import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiEndpointsUtils } from '../../api/endpoints/common-api-endponints.utils';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class MailingService extends BaseService {


  sendContactEmail(name: string, email: string, companyName: string, webUrl: string, comment: string): Observable<boolean> {
    return super.sendPostRequest(CommonApiEndpointsUtils.getSendContactEmailEndpoint(), CommonApiEndpointsUtils.getSendContactEmailObject(name, email, companyName, webUrl, comment), CommonApiEndpointsUtils.getSendContactEmailEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


}
