<div id="publicLanding">
    <!-- {{ 'home.Conócenos' | translate }} -->
    <app-header-landing (menuItemSelected)="onHeaderItemSelected($event)"></app-header-landing>

    <!-- Header -->
    <header id="{{ homeAnchor }}" class="landing-page header p-relative bg-section-white">
        <div class="header-shape header-shape-circle-1">
        </div>
        <div class="header-shape header-shape-circle-2">
        </div>
        <div class="section-primary header-content">
            <div class="container-fuild">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="text-container">
                            <h1 class="black-color">{{ 'home.Todo lo que necesitas en una ' | translate }}<span class="primary-color">{{ 'home.única plataforma' | translate }}</span></h1>
                            <p class="black-color p-large">{{ 'home.Booqlever es un software de ' | translate }}<span class="font-weight-bold primary-color">{{ 'home.gestión de reservas ' | translate }}</span>{{ 'home.para proveedores del sector turístico.' | translate }}</p>
                            <a (click)="goToContact()" class="btn btn-solid mr-4 mb-4 page-scroll">{{ 'home.¡Pruébalo!' | translate }}</a>
                            <a (click)="goToPlansLanding()" class="btn btn-outline mb-4 page-scroll">{{ 'home.Conoce nuestros planes' | translate }}</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="image-container text-center mt-5 mt-md-auto">
                            <img class="img-fluid" src="assets/img/header-mockup.png" alt="{{ 'home.Captura del dashboard de Booqlever' | translate }}">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 arrow-center">
                        <img class="img-fluid" src="assets/img/ic_arrow_down-primary.svg" alt="{{ 'home.Seguir leyendo' | translate }}">
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- end of header -->

    <!-- Features -->
    <div id="{{ featuresAnchor }}" class="landing-page features bg-section-gray">
        <div class="section-primary section-title">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 white-color text-center">
                        <h2 class="black-color">{{ 'home.Detalles y características' | translate }}</h2>
                        <div class="p-heading p-large black-color opacity-2">{{ 'home.Lo que tenemos que ofrecer a tu negocio' | translate }}</div>
                    </div>
                </div>
                <div class="row mt-0 mt-lg-5 pt-0 pt-xl-5">
                    <div class="col-12 col-lg-3 text-center text-lg-right text-xl-center">
                        <div class="row mb-5">
                            <div class="col-auto col-lg-12 mx-auto">
                                <img class="img-fluid mb-3" src="assets/img/ic_feature_tour.svg">
                            </div>
                            <div class="col-12 col-lg-12 my-auto">
                                <h4>{{ 'home.Gestiona tours y actividades' | translate }}</h4>
                            </div>
                            <div class="col-12">
                                <p>{{ 'home.Da de alta tus propios tours y actividades y modifica precios o fechas.' | translate }}</p>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-auto col-lg-12 mx-auto">
                                <img class="img-fluid mb-3" src="assets/img/ic_feature_clock.svg">
                            </div>
                            <div class="col-12 col-lg-12 my-auto">
                                <h4>{{ 'home.Gestiona tu tiempo' | translate }}</h4>
                            </div>
                            <div class="col-12">
                                <p>{{ 'home.Administra y conoce en todo momento el estado de tus reservas.' | translate }}</p>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-auto col-lg-12 mx-auto">
                                <img class="img-fluid mb-3" src="assets/img/ic_feature_calendar.svg">
                            </div>
                            <div class="col-12 col-lg-12 my-auto">
                                <h4>{{ 'home.Organización' | translate }}</h4>
                            </div>
                            <div class="col-12">
                                <p>{{ 'home.Tus reservas serán automáticamente calendarizadas.' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-5 my-lg-auto">
                        <img class="img-fluid" src="assets/img/mockup-1.png" alt="{{ 'home.Captura del dashboard de Booqlever' | translate }}">
                    </div>
                    <div class="col-12 col-lg-3 text-center text-lg-left text-xl-center">
                        <div class="row mb-5">
                            <div class="col-auto col-lg-12 mx-auto">
                                <img class="img-fluid mb-3" src="assets/img/ic_feature_books.svg">
                            </div>
                            <div class="col-12 col-lg-12 my-auto">
                                <h4>{{ 'home.Gestiona reservas' | translate }}</h4>
                            </div>
                            <div class="col-12">
                                <p>{{ 'home.Conoce de un vistazo tus próximas reservas y olvídate de los problemas de disponibilidad.' | translate }}</p>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-auto col-lg-12 mx-auto">
                                <img class="img-fluid mb-3" src="assets/img/ic_feature_people.svg">
                            </div>
                            <div class="col-12 col-lg-12 my-auto">
                                <h4>{{ 'home.Controla la asistencia' | translate }}</h4>
                            </div>
                            <div class="col-12">
                                <p>{{ 'home.Actualiza de forma sencilla los datos de asistencia de cada tour.' | translate }}</p>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col-auto col-lg-12 mx-auto">
                                <img class="img-fluid mb-3" src="assets/img/ic_feature_responsive.svg">
                            </div>
                            <div class="col-12 col-lg-12 my-auto">
                                <h4>{{ 'home.Para móvil y escritorio' | translate }}</h4>
                            </div>
                            <div class="col-12">
                                <p>{{ 'home.Disponible para móvil, tablet y ordenador.' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end of features -->


    <!-- Video
<div id="preview" class="landing-page preview bg-section-white">
    <div class="section-primary">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-xl-6 text-center text-xl-left mt-xl-5">
                    <h1 class="black-color">{{ 'home.Pruébalo ' | translate }}<span class="primary-color">{{ 'home.gratis' | translate }}</span>{{ 'home. durante 14 días' | translate }}</h1>
                    <p class="black-color p-large">{{ 'home.Y disfruta de las ventajas de gestionar tu negocio desde una única plataforma.' | translate }}</p>
                    <a class="btn btn-solid w-100 w-lg-50 mt-3">{{ 'home.¡Pruébalo!' | translate }}</a>
                </div>
                <div class="col-12 col-xl-6 mt-5 mt-lg-auto">
                    <div class="image-container">
                        <div class="video-wrapper">
                            <a class="popup-youtube" href="https://www.youtube.com/" data-effect="fadeIn">
                                <img class="img-fluid" src="assets/img/mockup-preview.png" alt="{{ 'home.Captura del vídeo demostrativo de la aplicación' | translate }}">
                                <span class="video-play-button"><span></span></span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
end of video -->

    <!-- Call to action -->
    <div id="cta" class="landing-page cta-primary-section bg-section-degradate">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2 text-center">
                    <h3 class="cta white-color mb-5">{{ 'home.Con Booqlever podrás trabajar desde cualquier parte, desde cualquier dispositivo y en cualquier momento.' | translate }}</h3>
                    <a (click)="goToContact()" class="btn btn-solid btn-solid-light w-75 w-lg-25">{{ 'home.¡Pruébalo!' | translate }}</a>
                </div>
            </div>

        </div>
    </div>
    <!-- end of call to action -->

    <!-- Advantage -->
    <div id="{{ advantagesAnchor }}" class="landing-page advantage bg-section-white">
        <div class="section-primary">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 d-xl-none">
                        <h3 class="cta primary-color">{{ 'home.Ventajas' | translate }}</h3>
                        <h1 class="black-color">{{ 'home.Dashboard potente y ' | translate }}<span class="primary-color">{{ 'home.sencillo' | translate }}</span> </h1>
                        <p class="black-color p-large">{{ 'home.Tus datos estarán seguros y protegidos en la nube y según la normativa GDPR' | translate }}</p>
                    </div>

                    <div class="col-12 col-xl-5 mb-5 pb-5 mb-xl-auto">
                        <div class="image-container text-center mt-5 mt-md-auto">
                            <img class="img-fluid" src="assets/img/mockup-advantages.png" alt="{{ 'home.Mockup del dashboard de Booqlever' | translate }}">
                        </div>
                    </div>

                    <div class="col-12 col-xl-7 text-left mt-xl-5">
                        <div class="row d-none d-xl-block">
                            <div class="col-12">
                                <h3 class="cta primary-color">{{ 'home.Ventajas' | translate }}</h3>
                                <h1 class="black-color">{{ 'home.Dashboard potente y ' | translate }}<span class="primary-color">{{ 'home.sencillo' | translate }}</span> </h1>
                                <p class="black-color p-large">{{ 'home.Tus datos estarán seguros y protegidos en la nube y según la normativa GDPR' | translate }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-xl-6">
                                <p class="title">{{ 'home.Ahorra tiempo' | translate }}</p>
                                <p>{{ 'home.Automatiza la gestión de tu negocio con pagos de forma segura y recibe notificaciones por email o recordatorios en función de la disponibilidad del tour o actividad.' | translate }}</p>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                                <p class="title">{{ 'home.SEO friendly' | translate }}</p>
                                <p>{{ 'home.Amplia tu presencia en internet con un contenido optimizado que mejorará el tráfico de tu web.' | translate }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-xl-6">
                                <p class="title">{{ 'home.Fácil integración' | translate }}</p>
                                <p>{{ 'home.Integración con gestores de contenido como con Wordpress, Joomla o Wix. Y sistemas de pago seguro como PayPal o Stripe.' | translate }}</p>
                            </div>
                            <div class="col-12 col-md-6 col-xl-6">
                                <p class="title">{{ 'home.100% Cloud y Seguro' | translate }}</p>
                                <p>{{ 'home.Tus datos se guardarán de forma segura en la nube y cumpliendo con la ' | translate }}<span class="font-weight-bold">{{ 'home.normativa europea GDPR.' | translate }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end of Advantage -->

    <!-- Call to action -->
    <div id="ctaPrimary" class="landing-page cta-primary-section bg-section-gray">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 col-lg-8 offset-lg-2 text-center">
                    <h3 class="black-color mb-5 pb-4">{{ 'home.Integración con las principales ' | translate }}<span class="primary-color">{{ 'home.pasarelas de pago ' | translate }}</span>{{ 'home.y gestores de contenido web' | translate }}</h3>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-auto mb-5 my-lg-auto">
                    <img class="img-fluid logo-cta" src="assets/img/logo_papypal.png" alt="{{ 'home.Logotipo de Paypal' | translate }}">
                </div>
                <div class="col-auto mb-5 my-lg-auto">
                    <img class="img-fluid logo-cta" src="assets/img/logo_stripe.png" alt="{{ 'home.Logotipo de Stripe' | translate }}">
                </div>
                <div class="col-auto mb-5 my-lg-auto">
                    <img class="img-fluid logo-cta" src="assets/img/logo_wordpress.png" alt="{{ 'home.Logotipo de Wordpress' | translate }}">
                </div>
                <div class="col-auto mb-5 my-lg-auto">
                    <img class="img-fluid logo-cta" src="assets/img/logo_wix.png" alt="{{ 'home.Logotipo de Wix' | translate }}">
                </div>
                <div class="col-auto mb-5 my-lg-auto">
                    <img class="img-fluid logo-cta" src="assets/img/logo_joomla.png" alt="{{ 'home.Logotipo de Joomla' | translate }}">
                </div>
            </div>
        </div>

    </div>
    <!-- end of call to action 1 -->

    <!-- Plans -->
    <div id="plans" class="landing-page plans bg-section-white">
        <div class="section-primary">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 white-color text-center">
                        <h2 class="black-color">{{ 'home.Elige tu plan' | translate }}</h2>
                        <div class="p-heading p-large black-color opacity-2">{{ 'home.Y pruébalo gratis durante 14 días' | translate }}</div>
                    </div>
                </div>

                <div class="row content-row justify-content-center">
                    <div class="col-md-12 col-lg-4 mb-5 mb-lg-0">
                        <div class="card card-plans text-center h-100">
                            <div class="card-body">
                                <div class="plan-title">{{ 'home.Basic' | translate }}</div>
                                <hr class="divider-primary">
                                <div class="plan-description">
                                    <p class="price mb-0">39<span class="small">€</span></p>
                                    <p class="month">{{ 'home.al mes' | translate }}</p>
                                </div>
                                <hr class="divider-secondary mx-auto">
                                <div class="plan-features text-left">
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.5 servicios' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Límite de 1000 por año' | translate }}</p>
                                </div>
                            </div>
                            <div class="card-footer">
                                <p><a (click)="goToFeaturedPlans()" class="cta-link">{{ 'home.Ver todos los detalles' | translate }}</a></p>
                                <p class="w-100 mb-0">
                                    <button class="btn-primary cta-btn">{{ 'home.Me interesa' | translate }}</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4 mb-5 mb-lg-0">
                        <div class="card card-plans text-center h-100">
                            <div class="card-body">
                                <div class="plan-title">{{ 'home.Advanced' | translate }}</div>
                                <hr class="divider-primary">
                                <div class="plan-description">
                                    <p class="price mb-0">79<span class="small">€</span></p>
                                    <p class="month">{{ 'home.al mes' | translate }}</p>
                                </div>
                                <hr class="divider-secondary mx-auto">
                                <div class="plan-features text-left">
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.10 servicios' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Límite de 2500 por año' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Permite pagos online' | translate }}</p>

                                </div>

                            </div>
                            <div class="card-footer">
                                <p><a (click)="goToFeaturedPlans()" class="cta-link">{{ 'home.Ver todos los detalles' | translate }}</a></p>
                                <p class="w-100 mb-0">
                                    <button class="btn-primary cta-btn">{{ 'home.Me interesa' | translate }}</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--
                <div class="card card-plans card-plan-pro text-center h-100 box-shadow-0">
                    <div class="card-body px-0 px-sm-3">
                        <div class="plan-title">{{ 'plans.PRO' | translate }}</div>
                        <hr class="divider-primary my-1 my-md-3">
                        <p class="month opacity-2 d-none d-md-block">{{ 'plans.Para OTAs y Marketplaces' | translate }}</p>

                        <div class="plan-description">
                            <p class="price mb-0">{{ 'plans.A consultar' | translate }}</p>
                            <p class="month d-md-none">{{ 'plans.Para OTAs y Marketplaces' | translate }}</p>
                        </div>
                        <p class="w-100 mb-0">
                            <button class="btn-primary cta-btn">{{ 'plans.Me interesa' | translate }}</button>
                        </p>
                    </div>
                </div> -->
                    <div class="col-md-12 col-lg-4 mb-5 mb-lg-0">
                        <div class="card card-plans card-plan-pro text-center h-100">
                            <div class="card-body">
                                <div class="plan-title">{{ 'home.PRO' | translate }}</div>
                                <hr class="divider-primary">
                                <p class="month opacity-2">{{ 'plans.Para OTAs y Marketplaces' | translate }}</p>

                                <div class="plan-description">
                                    <p class="price mb-0">{{ 'plans.A consultar' | translate }}</p>
                                </div>
                                <hr class="divider-secondary mx-auto">
                                <div class="plan-features text-left">
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.25 servicios' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Límite de 6000 por año' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Permite pagos online' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Planes para afiliados y resellers' | translate }}</p>
                                    <p class="mb-2">
                                        <img class="img-fluid ic-list" src="assets/img/ic_list.svg">{{ 'home.Acceso a staff/mánagers' | translate }}</p>
                                </div>

                            </div>
                            <div class="card-footer">
                                <p><a (click)="goToFeaturedPlans()" class="cta-link">{{ 'home.Ver todos los detalles' | translate }}</a></p>
                                <p class="w-100 mb-0">
                                    <button class="btn-primary cta-btn">{{ 'home.Me interesa' | translate }}</button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end of Plans -->

    <!-- Contact -->
    <div id="{{ contactAnchor }}" class="landing-page bg-section-gray h-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-5 bg-contact-form">
                </div>
                <div class="col-xl-7 contact-form text-center">
                    <h1 class="mb-3">{{ 'home.Contacto' | translate }}</h1>
                    <p class="mb-5">{{ 'home.Si necesitas más información o quieres ' | translate }}<span class="font-weight-bold">{{ 'home.de una prueba gratuita de 14 días ' | translate }}</span>{{ 'home.no dudes en ponerte en contacto con nosotros.' | translate }}</p>
                    <form class="form-boxes form-contact text-left" [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="field pb-2">
                                    <input formControlName="name" type="text" name="nameContact" id="nameContact" placeholder="" value="">
                                    <label for="nameContact">{{ 'home.Nombre' | translate }}</label>
                                </div>
                                <app-feedback [formGroup]="contactForm" field="name" validator="required" feedback="{{ 'home.Name required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="field pb-2">
                                    <input formControlName="email" type="email" name="emailContact" id="emailContact" placeholder="" value="">
                                    <label for="emailContact">{{ 'home.E-mail' | translate }}</label>
                                </div>
                                <app-feedback [formGroup]="contactForm" field="email" validator="required" feedback="{{ 'home.Email requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                                <app-feedback [formGroup]="contactForm" field="email" validator="email" feedback="{{ 'home.Email not valid' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="field pb-2">
                                    <input formControlName="companyName" type="text" name="nameEnterprise" id="nameEnterprise" placeholder="" value="">
                                    <label for="nameEnterprise">{{ 'home.Nombre de la empresa' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="field pb-2">
                                    <input formControlName="urlSite" type="text" name="webEnterprise" id="webEnterprise" placeholder="" value="">
                                    <label for="webEnterprise">{{ 'home.URL del sitio web' | translate }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="field pb-2">
                                    <textarea formControlName="comment" type="text" name="comment" id="comment" rows="8" cols=""></textarea>
                                    <label for="comment">{{ 'home.Comentario' | translate }}</label>
                                </div>
                                <app-feedback [formGroup]="contactForm" field="comment" validator="required" feedback="{{ 'home.Comentario requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <div class="field pb-2">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input (click)="onProvacyPolicyCheck()" formControlName="privacyPolicy" type="checkbox" class="custom-control-input" id="privacyPolicy" name="privacy-policy">
                                        <label class="custom-control-label" for="privacyPolicy">{{ 'singup.He leído y acepto la ' | translate }}<a class="terms-link" (click)="goToTerms()">{{ 'singup.Política de Privacidad' | translate }}</a>.</label>
                                    </div>
                                </div>
                                <app-feedback [formGroup]="contactForm" field="privacyPolicy" validator="required" feedback="{{ 'home.Es necesario que aceptes las condiciones para completar el registro' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <button (click)="onSendContact()" class="btn-primary btn-solid text-uppercase w-100 mt-3">{{ 'home.Solicitar prueba' | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- end of contact -->

    <app-message-panel></app-message-panel>
    <app-footer-landing></app-footer-landing>
</div>