<div id="publicLanding">
    <app-header-landing (menuItemSelected)="onHeaderItemSelected($event)"></app-header-landing>
    <!-- {{ 'plans.¡Pruébalo!' | translate }} -->
    <!-- Header -->
    <header id="header-plans" class="landing-page header p-relative bg-section-white">
        <div class="section-primary header-content">
            <div class="container-fuild">
                <div class="row">
                    <div class="col-12 col-lg-7 my-auto">
                        <div class="text-container mt-0">
                            <h1 class="black-color">{{ 'plans.Consigue tu ' | translate }}<span class="primary-color">{{ 'plans.prueba gratuita ' | translate }}</span>{{ 'plans.durante 14 días' | translate }}</h1>
                            <p class="black-color p-large mb-5">{{ 'plans.Sin necesidad de indicar datos de pago o facturación' | translate }}</p>
                            <a (click)="goToFeaturedPlans()" class="btn btn-solid text-uppercase w-100 w-lg-50 mr-4 mb-4 page-scroll">{{ 'plans.Ver planes' | translate }}</a>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="image-container text-center mt-5 mt-md-auto">
                            <img class="img-fluid" src="assets/img/mockup-advantages.png" alt="{{ 'plans.Mockup del dashboard de Booqlever' | translate }}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- end of header -->

    <!-- Features -->
    <div id="{{ featuredPlansAnchor }}" class="landing-page features bg-section-gray h-100">
        <div class="section-primary section-title bg-section-gray">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 white-color text-center">
                        <h2 class="black-color">{{ 'plans.Encuentra el plan que mejor se adapte a las necesidades tu negocio' | translate }}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 col-xl-3 offset-xl-3 px-0 px-sm-auto">
                        <div class="card card-plans text-center h-100 box-shadow-0">
                            <div class="card-body px-0 px-sm-3">
                                <div class="plan-title">{{ 'plans.Basic' | translate }}</div>
                                <hr class="divider-primary my-1 my-md-3">
                                <div class="plan-description">
                                    <p class="price mb-0">39<span class="small">€</span></p>
                                    <p class="month">{{ 'plans.al mes' | translate }}</p>
                                </div>
                                <p class="w-100 mb-0">
                                    <button class="btn-primary cta-btn">{{ 'plans.Me interesa' | translate }}</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-xl-3 px-0 px-sm-auto">
                        <div class="card card-plans text-center h-100 box-shadow-0">
                            <div class="card-body px-0 px-sm-3">
                                <div class="plan-title">{{ 'plans.Advanced' | translate }}</div>
                                <hr class="divider-primary my-1 my-md-3">
                                <div class="plan-description">
                                    <p class="price mb-0">79<span class="small">€</span></p>
                                    <p class="month">{{ 'plans.al mes' | translate }}</p>
                                </div>
                                <p class="w-100 mb-0">
                                    <button class="btn-primary cta-btn">{{ 'plans.Me interesa' | translate }}</button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 col-xl-3 px-0 px-sm-auto">
                        <div class="card card-plans card-plan-pro text-center h-100 box-shadow-0">
                            <div class="card-body px-0 px-sm-3">
                                <div class="plan-title">{{ 'plans.PRO' | translate }}</div>
                                <hr class="divider-primary my-1 my-md-3">
                                <p class="month opacity-2 d-none d-md-block">{{ 'plans.Para OTAs y Marketplaces' | translate }}</p>

                                <div class="plan-description">
                                    <p class="price mb-0">{{ 'plans.A consultar' | translate }}</p>
                                    <p class="month d-md-none">{{ 'plans.Para OTAs y Marketplaces' | translate }}</p>
                                </div>
                                <p class="w-100 mb-0">
                                    <button class="btn-primary cta-btn">{{ 'plans.Me interesa' | translate }}</button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-features">
                    <!-- Feature 1 -->
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="table-features-row table-feature-header pr-md-0" (click)="showFeatures()">
                                <p class="title mb-0">{{ 'plans.Características' | translate }}
                                    <img class="img-fluid ic-collapse float-right" src="assets/img/ic_arrow_down.svg">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="is-active-feature" [ngClass]="isShowFeatures ? 'show-feature' : ''">
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Servicios disponibles' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">5</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">10</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">25</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Límite de reservas' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">{{ 'plans.500 al mes' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">{{ 'plans.1500 al mes' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">{{ 'plans.ilimitadas' | translate }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Registro GDPR' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Integración WP, Joomla o Wix' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Control de asistencia' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Email recordatorio automático' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Afiliados y resellers' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Cloud system' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Copia de base de datos segura' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">{{ 'plans.Diaria' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">{{ 'plans.Cada 6h' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">{{ 'plans.Cada hora' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Actualizaciones automáticas' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row ">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>

                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end feature 1 -->

                    <!-- Feature 2 -->
                    <div class="row">
                        <div class="col-xl-12">
                            <div (click)="showPayments()" class="table-features-row table-feature-header pr-md-0">
                                <p class="title mb-0">{{ 'plans.Pagos' | translate }}
                                    <img class="img-fluid ic-collapse float-right" src="assets/img/ic_arrow_down.svg">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="is-active-feature" [ngClass]="isShowPayments ? 'show-feature' : ''">
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Procesamiento de pagos online' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Comisión por venta' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">0%</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">0%</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Integración con Stripe y PayPal' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Recibe pagos directamente en tu cuenta' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Petición de pago por email' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0"></p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0"></p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end feature 2 -->

                    <!-- Feature 3 -->
                    <div class="row">
                        <div class="col-xl-12">
                            <div (click)="showManagement()" class="table-features-row table-feature-header pr-md-0">
                                <p class="title mb-0">{{ 'plans.Gestión' | translate }}
                                    <img class="img-fluid ic-collapse float-right" src="assets/img/ic_arrow_down.svg">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="is-active-feature" [ngClass]="isShowManagement ? 'show-feature' : ''">
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Channel manager' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Estadísticas en tiempo real' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Acceso a staff/mánagers' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_no.svg" alt="{{ 'plans.No disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Notificaciones de aviso de pocas plazas' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Exportación datos a XML' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0"></p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0"></p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0"></p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Reserva directa desde el panel' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end feature 3 -->

                    <!-- Feature 4 -->
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="table-features-row table-feature-header pr-md-0 pointer-event" (click)="showSupport()">
                                <p class="title mb-0">{{ 'plans.Soporte' | translate }}
                                    <img class="img-fluid ic-collapse float-right" src="assets/img/ic_arrow_down.svg">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="is-active-feature" [ngClass]="isShowSupport ? 'show-feature' : ''">
                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Soporte por ticket' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 col-xl-3 pr-md-0">
                                <div class="table-features-row">
                                    <p class="content mb-0">{{ 'plans.Video Training' | translate }}</p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pr-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Basic' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 px-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan Advanced' | translate }}">
                                    </p>
                                </div>
                            </div>
                            <div class="col-4 col-xl-3 pl-0 px-lg-0 text-center mb-auto">
                                <div class="table-features-row">
                                    <p class="content result mb-0">
                                        <img class="img-fluid ic-feature" src="assets/img/ic_feature_yes.svg" alt="{{ 'plans.Disponible para plan PRO' | translate }}">
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- end feature 4 -->

                    <div class="row my-md-5 py-md-5 py-3 my-3">
                        <div class="col-12 col-md-8 offset-md-2 white-color text-center pt-5">
                            <h2 class="black-color">{{ 'plans.Prueba Booqlever gratis durante 14 días y comprueba qué plan es el mejor para tu negocio' | translate }}</h2>
                            <p class="black-color p-large mb-5">{{ 'plans.Sin necesidad de indicar datos de pago o facturación' | translate }}</p>
                            <a (click)="goToContact()" class="btn btn-solid text-uppercase w-100 w-lg-50 mt-5">{{ 'plans.Comenzar prueba' | translate }}</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- end of features -->
    <app-footer-landing></app-footer-landing>
</div>