import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ViewportScroller } from '@angular/common';

@Component({
     selector: 'app-plans',
     templateUrl: './plans.component.html'
})
export class PlansComponent implements AfterViewInit  {

     isShowFeatures: boolean = true;
     isShowPayments: boolean = true;
     isShowManagement: boolean = true;
     isShowSupport: boolean = true;

     targetAnchor: string;

     featuredPlansAnchor: string = NavigationConstants.NAVIGATION_PLANS_ANCHOR_FEATURED_PLANS;

     constructor(
          private router: Router,
          private viewportScroller: ViewportScroller,
          private activatedRoute: ActivatedRoute) {

               this.activatedRoute.fragment.subscribe((fragment: string) => {
                    this.targetAnchor = fragment;
               });
          }

          ngAfterViewInit() {
               if ( this.targetAnchor ) {
                    this.viewportScroller.scrollToAnchor(this.targetAnchor);
               }
          }


          showFeatures() {
               this.isShowFeatures = !this.isShowFeatures;
          }
          showPayments() {
               this.isShowPayments = !this.isShowPayments;
          }

          showManagement() {
               this.isShowManagement = !this.isShowManagement;
          }
          showSupport() {
               this.isShowSupport = !this.isShowSupport;
          }

          onHeaderItemSelected(anchor: string) {
               this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: anchor } );
          }

          goToFeaturedPlans() {
               this.viewportScroller.scrollToAnchor(NavigationConstants.NAVIGATION_PLANS_ANCHOR_FEATURED_PLANS);
          }

          goToContact() {
               this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT } );
          }



     }
