<link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">

<!-- Navigation -->
<nav id="navbarCustom" class="navbar navbar-expand-md navbar-dark navbar-custom fixed-top" [ngClass]="{'isScrolling': isScrolling }">

    <a class="navbar-brand logo-image d-none d-md-block" (click)="goToHomeConditional()">
        <img src="assets/img/logo-primary.png" alt="{{ 'headerHome.Logo de Booqlever' | translate }}">
    </a>

    <a class="navbar-brand logo-image d-md-none" (click)="goToHomeConditional()">
        <img src="assets/img/logo-white.png" alt="{{ 'headerHome.Logo de Booqlever' | translate }}">
    </a>

    <!-- Mobile Menu Toggle Button -->
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()"></button>
    <!-- end of mobile menu toggle button -->

    <div class="collapse navbar-collapse" [ngClass]="navbarOpen ? 'show' : ''">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a (click)="goToHome()" class="nav-link page-scroll text-uppercase">{{ 'headerHome.Home' | translate }}<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a (click)="goToFeatures()" class="nav-link page-scroll text-uppercase">{{ 'headerHome.Características' | translate }}</a>
            </li>
            <li class="nav-item">
                <a (click)="goToAdvantages()" class="nav-link page-scroll text-uppercase">{{ 'headerHome.Ventajas' | translate }}</a>
            </li>
            <li class="nav-item">
                <a (click)="goToPlansLanding()" class="nav-link page-scroll text-uppercase">{{ 'headerHome.Planes' | translate }}</a>
            </li>
            <li class="nav-item">
                <a (click)="goToContact()" class="nav-link page-scroll text-uppercase">{{ 'headerHome.Contacto' | translate }}</a>
            </li>
            <li class="nav-item nav-item-featured">
                <a (click)="goToDashboard()" class="nav-link nav-link-access page-scroll text-uppercase">
                    <img class="d-none d-md-block" src="assets/img/ic_access.svg">
                    <span class="d-md-none">{{ 'headerHome.Iniciar sesión' | translate }} </span>
                </a>
            </li>
            <li class="nav-item selector-lang my-auto">
                <app-language-picker></app-language-picker>
            </li>
        </ul>
    </div>
</nav>
<!-- end navigation -->

<a id="btnScrollToTop" (click)="scrollToTop()" class="btn-scroll-to-top bg-primary-color" [ngClass]="{'isShow': isScrolling }"></a>

<app-window-scroll-controller (pageYOffset)="onPageYOffsetChanged($event)"></app-window-scroll-controller>