import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ViewportScroller } from '@angular/common';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { MailingInteractor } from 'src/app/domain/interactor/public/mailing.interactor';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements AfterViewInit {


  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  refreshValidators: boolean;

  homeAnchor: string = NavigationConstants.NAVIGATION_HOME_ANCHOR_HOME;
  featuresAnchor: string = NavigationConstants.NAVIGATION_HOME_ANCHOR_FEATURES;
  advantagesAnchor: string = NavigationConstants.NAVIGATION_HOME_ANCHOR_ADVANTAGES;
  contactAnchor: string = NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT;

  targetAnchor: string;

  contactForm: FormGroup;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private activatedRoute: ActivatedRoute,
    private mailingInteractor: MailingInteractor,
    private serverErrorManager: ServerErrorManager) {

    this.refreshValidators = false;

    this.activatedRoute.fragment.subscribe((fragment: string) => {
      this.targetAnchor = fragment;
    });

    this.contactForm = new FormGroup({
      "name": new FormControl('', [Validators.required]),
      "email": new FormControl('', [Validators.required, Validators.email]),
      "companyName": new FormControl(''),
      "urlSite": new FormControl(''),
      "comment": new FormControl('', [Validators.required]),
      'privacyPolicy': new FormControl(false, [this.privacyPolicyValidator])
    });

  }


  privacyPolicyValidator(control: FormControl): { [s: string]: boolean } {
    if (control.parent && !control.value) {
      return {
        required: true
      }
    }
    return null;
  }

  onProvacyPolicyCheck() {
       if ( this.contactForm.get('privacyPolicy').value) {
            this.contactForm.get('privacyPolicy').setValue(false);
       } else {
            this.contactForm.get('privacyPolicy').setValue(true);
       }
  }


  ngAfterViewInit() {
    if (this.targetAnchor) {
      this.viewportScroller.scrollToAnchor(this.targetAnchor);
    }
  }

  goToPlansLanding() {
    this.router.navigate([NavigationConstants.getNavigationPlansLanding()]);
  }

  onHeaderItemSelected(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor);
  }

  goToContact() {
    this.viewportScroller.scrollToAnchor(NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT);
  }

  goToFeaturedPlans() {
    this.router.navigate([NavigationConstants.getNavigationPlansLanding()], { fragment: NavigationConstants.NAVIGATION_PLANS_ANCHOR_FEATURED_PLANS });
  }

  goToTerms() {
    this.router.navigate( [ NavigationConstants.getNavigationTerms() ] );
  }

  onSendContact() {

    this.refreshValidators = true;

    if (this.contactForm.valid) {

      this.messagePanelComponent.showLoading();

      this.mailingInteractor.sendContactEmail(
        this.contactForm.get('name').value, this.contactForm.get('email').value,
        this.contactForm.get('companyName').value, this.contactForm.get('urlSite').value,
        this.contactForm.get('comment').value)
        .subscribe(
          () => {
            MessagePanelComponent.hideLoading();
            this.messagePanelComponent.showSuccessMessage(I18N('home.Mensaje enviado correctamente'));
          },
          (error: GenericException) => {

            MessagePanelComponent.hideLoading();
            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
        )

    }

  }


}
