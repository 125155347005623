import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { MailingService } from 'src/app/business/services/public/mailing.service';


@Injectable({
  providedIn: 'root'
})
export class MailingInteractor {


  constructor(private mailingService: MailingService) { }

  sendContactEmail(name: string, email: string, companyName: string, webUrl: string, comment: string): Observable<boolean> {

    return Observable.create((observer: Observer<boolean>) => {
      this.mailingService.sendContactEmail(name, email, companyName, webUrl, comment)
        .subscribe(
          () => {

            observer.next(true);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });
  }


}
