import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AlreadyauthorizedGuard } from './ui/guards/alreadyauthorized.guard';
import { LoginComponent } from './ui/components/pages/access/login/login.component';
import { RememberPasswordComponent } from './ui/components/pages/access/remember-password/remember-password.component';
import { NavigationConstants } from './ui/commons/navigation.constants';
import { SingupComponent } from './ui/components/pages/access/singup/singup.component';
import { RememberPasswordConfirmationComponent } from './ui/components/pages/access/remember-password/remember-password-confirmation.component';
import { UserZoneRedirectGuard } from './ui/guards/userzoneredirect.guard';
import { TermsComponent } from './ui/components/pages/public/privacypolicy/terms.component';
import { ForbiddenComponent } from './ui/components/pages/public/error/forbidden/forbidden.component';
import { NotFoundComponent } from './ui/components/pages/public/error/not-found/not-found.component';
import { BookingCancelationComponent } from './ui/components/pages/public/booking/cancel/booking-cancelation.component';
import { ReviewComponent } from './ui/components/pages/public/booking/review/review.component';
import { ReviewThanksComponent } from './ui/components/pages/public/booking/review/thanks.component';
import { SingupAffiliateComponent } from './ui/components/pages/access/singup-affiliate/singup.component';
import { HomeComponent } from './ui/components/pages/public/home/home.component';
import { PlansComponent } from './ui/components/pages/public/plans/plans.component';
import { BookingPaymentComponent } from './ui/components/pages/public/booking/payment/booking-payment.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: NavigationConstants.NAVIGATION_LOGIN, component: LoginComponent, canActivate: [ AlreadyauthorizedGuard ] },
  { path: NavigationConstants.NAVIGATION_REMEMBER_PASSWORD, component: RememberPasswordComponent, canActivate: [ AlreadyauthorizedGuard ] },
  { path: NavigationConstants.NAVIGATION_REMEMBER_PASSWORD_CONFIRMATION, component: RememberPasswordConfirmationComponent, canActivate: [ AlreadyauthorizedGuard ] },
  { path: NavigationConstants.NAVIGATION_USER_ZONE, component: ForbiddenComponent, canActivate: [ UserZoneRedirectGuard ] },
  { path: NavigationConstants.NAVIGATION_PLANS, component: PlansComponent },
  { path: NavigationConstants.NAVIGATION_PROVIDER_REGISTER, component: SingupComponent },
  { path: NavigationConstants.NAVIGATION_AFFILIATE_REGISTER, component: SingupAffiliateComponent },
  { path: NavigationConstants.NAVIGATION_FORBIDDEN, component: ForbiddenComponent },
  { path: NavigationConstants.NAVIGATION_TERMS, component: TermsComponent },
  { path: NavigationConstants.NAVIGATION_REVIEW, component: ReviewComponent },
  { path: NavigationConstants.NAVIGATION_REVIEW_THANKS, component: ReviewThanksComponent },
  { path: NavigationConstants.NAVIGATION_BOOKIN_CANCELATION, component: BookingCancelationComponent },
  { path: NavigationConstants.NAVIGATION_BOOKIN_PAYMENT, component: BookingPaymentComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: "enabled",
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
